<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      class="a"
      width="24"
      height="24"
    />

    <path
      class="b"
      d="M665.524-660.774c-2.086-.709-2.524-1.616-2.524-2.253a2.23,2.23,0,0,1,1.365-1.755.469.469,0,0,1,.637.185.469.469,0,0,1-.185.637c-.558.307-.879.647-.879.933,0,.35.5.893,1.888,1.365a16.739,16.739,0,0,0,5.175.725,16.741,16.741,0,0,0,5.175-.725c1.392-.473,1.888-1.015,1.888-1.365,0-.286-.32-.626-.879-.933a.469.469,0,0,1-.226-.281A.469.469,0,0,1,677-664.6a.469.469,0,0,1,.637-.185A2.23,2.23,0,0,1,679-663.027c0,.637-.438,1.544-2.524,2.253A17.686,17.686,0,0,1,671-660,17.685,17.685,0,0,1,665.524-660.774Zm1.937-1.565c-1.164-.329-1.754-.8-1.754-1.415s.59-1.086,1.754-1.415a11.032,11.032,0,0,1,1.842-.333c-1.336-2.281-2.66-4.863-2.66-6.14A4.362,4.362,0,0,1,671-676a4.362,4.362,0,0,1,4.357,4.357c0,1.277-1.324,3.86-2.66,6.141a11.038,11.038,0,0,1,1.842.333c1.164.329,1.754.8,1.754,1.415s-.59,1.086-1.754,1.415a13.585,13.585,0,0,1-3.539.417,13.585,13.585,0,0,1-3.539-.418Zm.425-1.973a2.4,2.4,0,0,0-1.234.558,2.392,2.392,0,0,0,1.234.558,13.157,13.157,0,0,0,3.114.336,13.16,13.16,0,0,0,3.114-.336,2.392,2.392,0,0,0,1.234-.558,2.394,2.394,0,0,0-1.234-.558,11.4,11.4,0,0,0-1.95-.294l-.149.246a1.18,1.18,0,0,1-1.014.569,1.18,1.18,0,0,1-1.014-.569l-.149-.246a11.4,11.4,0,0,0-1.952.293Zm-.306-7.33c0,.969,1.2,3.508,3.206,6.792a.247.247,0,0,0,.214.12.247.247,0,0,0,.214-.12c2.007-3.284,3.206-5.824,3.206-6.792a3.424,3.424,0,0,0-3.42-3.42,3.424,3.424,0,0,0-3.42,3.42Zm1.669-.3A1.753,1.753,0,0,1,671-673.693a1.753,1.753,0,0,1,1.751,1.751A1.753,1.753,0,0,1,671-670.19a1.753,1.753,0,0,1-1.751-1.752Zm.938,0a.815.815,0,0,0,.814.814.815.815,0,0,0,.814-.814.815.815,0,0,0-.814-.814.815.815,0,0,0-.815.814Z"
      transform="translate(-659 680)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Available',
};
</script>

<style lang="scss" scoped>
  .a {
    fill: none;
  }

  .b {
    fill: currentColor;
  }
</style>
